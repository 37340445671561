import { useContext, useState, useEffect } from "react";
import { EnvironmentContext, environments, getCurrentSport } from "../../../config/EnvironmentContext";

import { useAuth0 } from "../../../Auth/react-auth0-wrapper";

export const getTokenKeyPath = (productCode) => {
  return [productCode, getCurrentSport(), "tokenDetails"].join("_");
};

export const getProductToken = (productCode) => {
  const tokenValue = localStorage.getItem(getTokenKeyPath(productCode));
  let tokenDetails;
  try {
    tokenDetails = tokenValue !== null ? JSON.parse(tokenValue) : null;
  } catch (error) {
    console.error("Unable to decode token");
  }
  return tokenDetails;
};

export const setProductToken = (productCode, value) => {
  localStorage.setItem(getTokenKeyPath(productCode), JSON.stringify(value));
};

export const clearProductToken = (productCode) => {
  localStorage.removeItem(getTokenKeyPath(productCode));
};

export const useAccess = (loading, isAuthenticated, productCode, customer) => {
  const [productToken, setToken] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [tokenStorage, setTokenStorage] = useState(null);
  const [getCustomers, setGetCustomers] = useState(false);
  const [customerList, setCustomerList] = useState(null);
  const [productList, setProductList] = useState(null);
  const { readBody = (body) => body.json() } = {};
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvironmentContext);
  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    let tokenDetails = getProductToken(productCode);
    setTokenStorage(tokenDetails);
    if (tokenDetails) {
      // let payload = JSON.parse(atob(tokenDetails.token.split(".")[1]));
      // console.log(tokenDetails);
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (!loading && isAuthenticated) {
        try {
          const authToken = await getTokenSilently();
          setAuthToken(authToken);
        } catch (e) {
          console.log("EE (getToken):", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    (async () => {
      if (tokenStorage) {
        if (checkExpiry(tokenStorage.expiry)) {
          setToken(tokenStorage);
        } else {
          if (authToken) {
            setGetCustomers(true);
          }
        }
      } else {
        if (authToken) {
          setGetCustomers(true);
        }
      }
    })();
    // eslint-disable-next-line
  }, [authToken, tokenStorage]);

  useEffect(() => {
    (async () => {
      if (authToken) {
        let url = environments.token[env] + "/v1/list/customers";
        try {
          let values = {
            userIdentityToken: authToken,
            product: productCode
          };
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            setCustomerList(body.data.customers);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.error("E", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [getCustomers]);

  useEffect(() => {
    (async () => {
      if (authToken && customer > 0) {
        setCustomerList(null);

        let url = "";
        let values = null;

        if (productCode === "datacore") {
          url = environments.token[env] + "/v1/oauth2/rest/token";
          values = {
            userIdentityToken: authToken,
            applicationId: environments.application[env],
            sport: getCurrentSport()
          };
          console.debug("Fetching datacore access token", values);
        } else {
          url = environments.token[env] + "/v1/products/token";
          values = {
            userIdentityToken: authToken,
            product: productCode,
            customerId: String(customer)
          };
        }
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json"
            },
            signal: signal,
            body: JSON.stringify(values)
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customer
            };
            setToken(token);
            setProductToken(productCode, token);
            setTokenStorage(token);
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [customer, authToken]);

  useEffect(() => {
    let productStorage = null;
    if (tokenStorage !== null) {
      productStorage =
        localStorage.getItem("productList_" + tokenStorage.customerId) !== null
          ? JSON.parse(localStorage.getItem("productList_" + tokenStorage.customerId))
          : null;
    }
    if (productStorage) {
      setProductList(productStorage);
    } else {
      (async () => {
        if (authToken && tokenStorage && tokenStorage.customerId > 0) {
          let url = environments.token[env] + "/v1/list/products";
          try {
            let values = {
              userIdentityToken: authToken,
              customerId: String(tokenStorage.customerId)
            };
            const response = await fetch(url, {
              method: "POST",
              headers: {
                Accept: "application/json"
              },
              signal: signal,
              body: JSON.stringify(values)
            });
            if (response.ok) {
              const body = await readBody(response);
              let productObject = {};
              body.data.products.forEach((product) => {
                productObject[product.code] = true;
              });
              localStorage.setItem("productList_" + tokenStorage.customerId, JSON.stringify(productObject));
              setProductList(productObject);
            } else {
              const body = await readBody(response);
              console.log("error", body);
            }
          } catch (e) {
            console.log("E", e);
          }
        }
      })();
    }
    // eslint-disable-next-line
  }, [tokenStorage, authToken]);

  function checkExpiry(tokenDate) {
    if (parseInt(tokenDate) > Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  return { productToken, customerList, productList };
};
