import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch.js";
import { useGetTitle } from "../Base/Hooks/getTitle.js";

import VenueInputRow from "./VenueInputRow.jsx";
import PageDisplay from "../Base/PageDisplay/PageDisplay.jsx";
import TableDisplay from "../Base/Shared/TableDisplay.jsx";
import SearchFilter from "../Base/Shared/SearchFilter.jsx";
import FormBuilder from "../Base/Forms/FormBuilder.jsx";
import formSetup from "./formSetup.js";
import locales from "../../config/locales.js";

const VenueInputs = (props) => {
  const { title, updateState, history, match, sport } = props;
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const [formData, setformData] = useState({
    sport,
    competitionId: match.params.competitionId,
    venueId: match.params.venueId
  });

  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" +
      sport +
      "/video/inputs?venueId=" +
      match.params.venueId +
      "&competitionId=" +
      match.params.competitionId +
      "&include=venues",
    "",
    refreshFlag
  );
  const { getTitle } = useGetTitle(
    "/v1/" + sport + "/o/" + match.params.organizationId + "/venues/" + match.params.venueId,
    match.params.venueId,
    "venueId",
    "nameLocal"
  );

  const columns = ["Name", "Provider", "Src No", "Resolution", "Feed Type", "Locale", "Audio Channels", ""];

  const LookupData = [];
  LookupData["locales"] = locales;

  useEffect(() => {
    if (data) {
      let filteredData = data !== null ? data.data : [];

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.name.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => (
        <VenueInputRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          match={match}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
          sport={sport}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
      formData.venueId = match.params.venueId;
      formData.competitionId = match.params.competitionId;
      formData.organizationId = match.params.organizationId;
      formData.sport = sport;
      setformData(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sport, searchText]);

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={getTitle + " Inputs"}
          error={error}
          pageTitle={getTitle + " Inputs - " + title}
          ref={pageRef}
          history={history}
        >
          <SearchFilter doSearch={setSearchText} />
          <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} /> <hr />
          <FormBuilder
            form="NewVenueInput"
            action={() => setRefreshFlag(true)}
            formSetup={formSetup}
            pageRef={pageRef}
            lookupData={LookupData}
            formData={formData}
          />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(VenueInputs);
