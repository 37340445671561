import React from "react";
import { Route, Redirect } from "react-router";

import MasterVenues from "./MasterVenues";
import EditMasterVenue from "./EditMasterVenue";
import MasterVenueLinks from "./MasterVenueLinks";
import MasterVenueInputs from "./MasterVenueInputs";
import EditMasterVenueInput from "./EditMasterVenueInput";

const MasterVenueRoutes = (props) => {
  const { title, updateState, sport } = props;
  return (
    <>
      <Route exact path={"/admin"} render={() => <Redirect to="/admin/mastervenues" />} />
      <Route
        exact
        path={"/admin/mastervenues"}
        props={props}
        render={(props) => <MasterVenues {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/mastervenues/:masterVenueId/edit"}
        render={(props) => <EditMasterVenue {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/mastervenues/:masterVenueId/links"}
        render={(props) => <MasterVenueLinks {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/mastervenues/:masterVenueId/inputs"}
        render={(props) => <MasterVenueInputs {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/admin/mastervenues/:masterVenueId/inputs/:videoInputId/edit"}
        render={(props) => <EditMasterVenueInput {...props} sport={sport} updateState={updateState} title={title} />}
      />
    </>
  );
};

export default MasterVenueRoutes;
