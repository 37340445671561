import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import Modal from "../Base/Modal/Modal";
import { useDelete } from "../Base/Hooks/delete";

const FixtureInputRow = (props) => {
  const { itemIndex, result, pageRef, setRefreshFlag, intl, match, sport } = props;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [masterVenueDeleteData, setmasterVenueDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" + sport + "/video/inputs/" + masterVenueDeleteData,
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        +deleteError,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "mastervenue.delete.success",
            defaultMessage: "Master Venue deleted successfully"
          }),
          "success",
          10
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setmasterVenueDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeletemasterVenue(param) {
    setDeleteModalText("You are about to delete link to " + param.name + ". Is this OK?");
    setDeleteModalOpen(true);
    setDeleteItemId(param.videoInputId);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deletemasterVenue() {
    setmasterVenueDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  return (
    <React.Fragment key={itemIndex}>
      <tr key={itemIndex} className={"deleting-" + deleteLoading}>
        <td>{result.name}</td>
        <td>{result.provider}</td>
        <td>{result.sourceNumber}</td>
        <td>
          {result.inputResolution} ({result.fps} fps)
        </td>
        <td>{result.feedType}</td>
        <td>{result.locale}</td>
        <td>
          <strong>Ambience: </strong>
          {result.audioAmbienceChannel}
          <br />
          <strong>Commentary: </strong>
          {result.audioCommentaryChannel}
        </td>
        <td className="text-right controls-2">
          <Link
            to={
              "/organizations/" +
              match.params.organizationId +
              "/seasons/" +
              match.params.seasonId +
              "/fixtures/" +
              match.params.fixtureId +
              "/inputs/" +
              result.videoInputId +
              "/edit"
            }
            title="Edit Input"
            className="btn btn-outline-primary btn-sm"
          >
            <i className="fas fa-pen" />
          </Link>{" "}
          <Button outline color="danger" size="sm" onClick={(e) => showDeletemasterVenue(result, e)}>
            <i className="fas fa-minus" />
          </Button>
          <Modal
            isOpen={deleteModalOpen}
            heading="Delete Master Venue link?"
            text={deleteModalText}
            actionText={formatMessage({
              id: "delete",
              defaultMessage: "Delete"
            })}
            cancelText={formatMessage({
              id: "cancel",
              defaultMessage: "Cancel"
            })}
            action={deletemasterVenue}
            cancel={cancelModal}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default injectIntl(FixtureInputRow);
