// vendors
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { ENVIRONMENT, getSentryConfig } from "./config/Environment";

// Wrapper object - for when tracing library cannot load due to ad-blockers
export const Tracing = {
  init: () => {
    try {
      if (ENVIRONMENT === "dev") {
        console.debug("Tracing disabled for dev environment");
        return;
      }
      // Config loading must not fail
      const config = getSentryConfig();
      // Sentry init must not fail
      Sentry.init({
        ...config,
        integrations: [
          new Integrations.BrowserTracing({
            // Prevent sending sentry-tracing header to all requests and create CORS issues
            tracingOrigins: ["::1"]
          }),
          new Sentry.Integrations.Breadcrumbs({
            // This avoids console hijacking
            console: false
          })
        ]
      });
    } catch (error) {
      console.error("Tracing init - wrong setup", error);
    }
  },
  capture: (e) => {
    try {
      Sentry.captureException(e);
    } catch (error) {
      console.error("Tracing capture error - wrong setup", error.message, "unable to handle", e);
    }
  },
  captureMessage: (m) => {
    try {
      Sentry.captureMessage(m);
    } catch (error) {
      console.error("Tracing capture message - wrong setup", error.message, "unable to handle", m);
    }
  },
  setUser: (u) => {
    try {
      Sentry.setUser(u);
    } catch (error) {
      console.error("Tracing user profile - wrong setup", error.message, "unable to handle", u);
    }
  }
};
