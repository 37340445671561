import React from "react";
import { Route } from "react-router";

import Fixtures from "./Fixtures";
import FixtureInputs from "./FixtureInputs";
import EditFixtureInput from "./EditFixtureInput";

const FixtureRoutes = (props) => {
  const { title, updateState, sport } = props;
  return (
    <>
      <Route
        exact
        path={"/organizations/:organizationId/seasons/:seasonId/fixtures"}
        render={(props) => <Fixtures {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/organizations/:organizationId/seasons/:seasonId/fixtures/:fixtureId/inputs"}
        render={(props) => <FixtureInputs {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/organizations/:organizationId/seasons/:seasonId/fixtures/:fixtureId/inputs/:videoInputId/edit"}
        render={(props) => <EditFixtureInput {...props} sport={sport} updateState={updateState} title={title} />}
      />
    </>
  );
};

export default FixtureRoutes;
