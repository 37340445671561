import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { injectIntl } from "react-intl";

const SearchFilter = (props) => {
  const { doSearch, intl, initial } = props;
  const { formatMessage } = intl;

  return (
    <FormGroup className="customerSearch searchFilter">
      <Label for="search">
        <i className="fas fa-search" />
      </Label>
      <Input
        type="search"
        name="search"
        id="search"
        value={initial}
        placeholder={formatMessage({
          id: "search",
          defaultMessage: "search"
        })}
        onChange={(e) => doSearch(e.target.value)}
      />
    </FormGroup>
  );
};

export default injectIntl(SearchFilter);
