import React, { useState, useCallback } from "react";
import { injectIntl } from "react-intl";
import { useLocalStorage } from "../Hooks/storage";

const sports = [
  {
    code: "basketball",
    name: "Basketball"
  },
  {
    code: "basketball3",
    name: "Basketball3"
  },
  {
    code: "handball",
    name: "Handball"
  },
  {
    code: "volleyball",
    name: "Volleyball"
  }
];

const SportsSelector = (props) => {
  const [sport, setSport] = useLocalStorage("currentSport", "basketball");
  const onSportChange = useCallback(
    (e) => {
      localStorage.setItem("currentSport", e.target.value);
      setSport(e.target.value);
      if (props?.onSportChange) {
        props?.onSportChange(e.target.value);
      }
      window.location.reload();
    },
    [props, setSport]
  );
  return (
    <div className="SportSelector">
      <select value={sport} onChange={onSportChange}>
        {sports.map((sport) => {
          return (
            <option key={sport.code} value={sport.code}>
              {sport.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default injectIntl(SportsSelector);
