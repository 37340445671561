import { useContext, useState, useEffect } from "react";
import { useToken } from "./token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";
const defaultOpts = {};

export const useFetch = (input, opts = defaultOpts, refreshFlag = true, location = "location") => {
  const { token } = useToken();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { readBody = (body) => body.json() } = opts;
  const env = useContext(EnvironmentContext);
  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    (async () => {
      if (input && refreshFlag && token) {
        let url = environments[location][env] + input;
        setLoading(true);
        let tokenType = token.tokenType.charAt(0).toUpperCase() + token.tokenType.substr(1).toLowerCase();
        try {
          const response = await fetch(url, {
            headers: {
              Authorization: tokenType + " " + token.token
            },
            signal: signal
          });
          if (response.ok) {
            const body = await readBody(response);
            setData(body);
          } else {
            const body = await readBody(response);
            console.error("error", body);
            setError(body.message);
          }
        } catch (e) {
          console.error("Request error", e.message);
          setError(e.message);
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line
  }, [input, opts, refreshFlag, token]);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return { error, loading, data };
};
