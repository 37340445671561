import { useContext, useState, useEffect } from "react";
import { useToken } from "./token";
import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";

export const useGetTitle = (input, value, field, result) => {
  const { token } = useToken();
  const [getTitle, setTitle] = useState("");
  const { readBody = (body) => body.json() } = {};
  const env = useContext(EnvironmentContext);
  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    (async () => {
      if (input && token) {
        let url = environments.location[env] + input;
        let tokenType = token.tokenType.charAt(0).toUpperCase() + token.tokenType.substr(1).toLowerCase();
        try {
          const response = await fetch(url, {
            headers: {
              Authorization: tokenType + " " + token.token
            },
            signal: signal
          });
          if (response.ok) {
            const body = await readBody(response);
            body.data.forEach((item) => {
              if (item[field] === value) {
                setTitle(item[result]);
              }
            });
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        }
      }
    })();
    // eslint-disable-next-line
  }, [input, token]);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return { getTitle };
};
