import React, { useContext, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert, Container, Row, Col } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { Button, FormGroup, Label, Input, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

import LookupField from "./LookupField";
import LookupDataField from "./LookupDataField";
import { useToken } from "../Hooks/token";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { EnvironmentContext, environments } from "../../../config/EnvironmentContext";

const FormBuilder = (props) => {
  const { form, formSetup, formData, dataId, lookupData, customFields, intl } = props;
  const { formatMessage } = intl;
  const { token } = useToken();
  const [responseData, setData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [linkPath, setLinkPath] = useState("");
  const { readBody = (body) => body.json() } = {};

  const env = useContext(EnvironmentContext);

  const toggle = () => setModalOpen(!modalOpen);

  function isEmail(value) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) && value !== "") {
      return false;
    }
    return true;
  }

  function doSubmit(values = formValues) {
    setFormSubmitting(true);
    setModalOpen(false);
    // let values = formValues;
    let endpoint = formSetup.forms[form].endpoint;
    formSetup.forms[form].parameters.map((parameter, key) => {
      endpoint = endpoint.replace("#" + key, formData[parameter]);
      return null;
    });
    setShowSuccess(false);
    setTimeout(() => {
      formSetup.forms[form].fields.map((field, key) => {
        if (field.type === "lookup" || field.type === "number") {
          values[field.name] = parseInt(values[field.name]) || values[field.name];
        }
        if (field.type === "select-boolean" || field.type === "boolean") {
          if (typeof values[field.name] === "string") {
            switch (values[field.name].toLowerCase().trim()) {
              case "true":
                values[field.name] = true;
                break;
              case "false":
                values[field.name] = false;
                break;
              default:
                values[field.name] = false;
            }
          }
        }
        if (field.conditionValue && field.conditionValue !== setupValues[field.condition]) {
          delete values[field.name];
        }
        if (field.name.indexOf(".") > -1) {
          delete values[field.name];
        }

        return null;
      });
      if (formSetup.forms[form].method !== "CUSTOM") {
        (async () => {
          try {
            const response = await fetch(
              environments.location[env] + "/" + endpoint,

              {
                method: formSetup.forms[form].method,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: token.tokenType + " " + token.token
                },
                mode: "cors",
                body: JSON.stringify(values)
              }
            );
            if (response.ok) {
              const body = await readBody(response);
              let successmessage = null;
              if (formSetup.forms[form].successmessagetranslation) {
                successmessage = formatMessage({
                  id: formSetup.forms[form].successmessagetranslation,
                  defaultMessage: formSetup.forms[form].successmessage
                });
              } else {
                successmessage = formSetup.forms[form].successmessage;
              }
              if (props.pageRef) {
                props.pageRef.current.addFlash(successmessage + " - " + linkPath, "success", 5);
              } else {
                setShowSuccess(true);
              }
              if (formSetup.forms[form].link) {
                formSetup.forms[form].link.parameters.map((parameter, key) => {
                  setLinkPath(formSetup.forms[form].link.path.replace("#" + key, body.data[0][parameter]));
                  return null;
                });
              }
              setFormSubmitting(false);
              setData(body.data[0]);
              var getType = {};
              if (getType.toString.call(props.action) === "[object Function]") {
                props.action(values, body);
              }
            } else {
              const body = await readBody(response);
              if (props.pageRef) {
                props.pageRef.current.addFlash(body.message, "danger", 15);
              }
              setFormSubmitting(false);
            }
          } catch (e) {
            console.log(e);
            if (props.pageRef) {
              props.pageRef.current.addFlash(String(e), "danger", 15);
            }
            setFormSubmitting(false);
          }
        })();
      }
      if (formSetup.forms[form].method === "CUSTOM") {
        props.action(values);
        setFormSubmitting(false);
      }
      // setSubmitting(false);
    }, 400);
  }

  let setupValues = {};
  formSetup.forms[form].fields.map((field, key) => {
    if (field.initial === "dataId") {
      setupValues[field["name"]] = parseInt(dataId);
    } else if (field.initial !== null && field.name.search("Date") >= 0) {
      var date = new Date(formData[field.initial]);
      var dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
      setupValues[field["name"]] = dateString;
    } else if (field.initial !== null && field.initial.search("##") >= 0) {
      setupValues[field["name"]] = field.initial.replace("##", "");
    } else {
      setupValues[field["name"]] = field.initial !== null ? formData[field.initial] : "";
    }
    if (setupValues[field["name"]] === null) {
      setupValues[field["name"]] = "";
    }
    if (field.type === "lookup") {
      // setupValues[field["name"]] = setupValues[field["name"]];
    }
    if (field.type === "checkboxarray") {
      if (Array.isArray(setupValues[field["name"]]) === false) {
        setupValues[field["name"]] = [];
      }
    }
    if (field.initial && field.initial.indexOf(".") > 0) {
      let fieldParts = field.initial.split(".");
      if (typeof setupValues[fieldParts[0]] === "undefined") {
        setupValues[fieldParts[0]] = {};
      }
      if (formData[fieldParts[0]] !== null && formData[fieldParts[0]][fieldParts[1]] !== null) {
        setupValues[fieldParts[0]][fieldParts[1]] = formData[fieldParts[0]][fieldParts[1]];
      }
    }

    return null;
  });
  const dateTimeInput = ({ field, form: { touched, errors }, ...props }) => (
    <Input invalid={!!(touched[field.name] && errors[field.name])} {...field} {...props} />
  );
  let fields = formSetup.forms[form].fields.map((field, index) => (
    <React.Fragment key={index}>
      {field.conditionValue === setupValues[field.condition] && (
        <FormGroup key={index} className={field.size + " hidden-" + field.hidden}>
          <Label for={field.name}>
            {!field.labeltranslation && field.label}
            {field.labeltranslation && <FormattedMessage id={field.labeltranslation} defaultMessage={field.label} />}
          </Label>
          {(field.type === "text" || field.type === "email" || field.type === "number") && (
            <Field
              type={field.type}
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
              className={field.className}
            />
          )}
          {field.type === "textarea" && (
            <Field
              type={field.type}
              component={field.type}
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
              className={field.className}
              rows={field.rows}
            />
          )}
          {field.type === "date" && (
            <Field
              type={field.type}
              component={dateTimeInput}
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
              className={field.className}
              rows={field.rows}
            />
          )}
          {field.type === "custom" && customFields[field["name"]]}
          {field.type === "select" && (
            <Field
              type={field.type}
              component={field.type}
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
              className={field.className}
            >
              {field.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {!option.translation && option.text}
                  {option.translation &&
                    formatMessage({
                      id: option.translation,
                      defaultMessage: option.text
                    })}
                </option>
              ))}
            </Field>
          )}
          {field.type === "select-boolean" && (
            <Field
              type={field.type}
              component="select"
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
              className={field.className}
            >
              {field.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {!option.translation && option.text}
                  {option.translation &&
                    formatMessage({
                      id: option.translation,
                      defaultMessage: option.text
                    })}
                </option>
              ))}
            </Field>
          )}
          {field.type === "lookup" && (
            <Field
              type="select"
              component="select"
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
              className={field.className}
              defaultValue={setupValues[field["name"]]}
            >
              <LookupField lookup={field.lookup} />
            </Field>
          )}
          {field.type === "lookupData" && (
            <Field
              type="select"
              component="select"
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
              className={field.className}
              defaultValue={setupValues[field["name"]]}
            >
              <LookupDataField lookup={field.lookupData} lookupData={lookupData} initial={setupValues[field["name"]]} />
            </Field>
          )}
          {field.type === "checkboxarray" && (
            <React.Fragment>
              <FieldArray
                name={field.name}
                id={field.name}
                className={field.className}
                render={(arrayHelpers) => (
                  <div>
                    {field.options.map((option, index) => (
                      <div key={option} className={"checkbox " + field.optionsClass}>
                        <label>
                          <input
                            name={field.name}
                            type="checkbox"
                            value={option}
                            checked={setupValues[field["name"]].includes(option)}
                            onChange={(e) => {
                              setupValues[field["name"]] = Array.isArray(setupValues[field["name"]])
                                ? setupValues[field["name"]]
                                : [];
                              if (e.target.checked) {
                                arrayHelpers.push(option);
                                setupValues[field["name"]].push(option);
                              } else {
                                const idx = setupValues[field["name"]].indexOf(option);
                                setupValues[field["name"]].splice(idx, 1);
                                arrayHelpers.remove(idx);
                              }
                            }}
                          />
                          <span className="cr">
                            <i className="cr-icon fa fa-check" />
                          </span>
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              />
            </React.Fragment>
          )}
          <ErrorMessage name={field.name}>{(msg) => <div className="formError">{msg}</div>}</ErrorMessage>
        </FormGroup>
      )}
    </React.Fragment>
  ));

  return (
    <Container className={formSetup.forms[form].formClass}>
      <Row>
        <Col>
          <h3>
            {formSetup.forms[form].headingIcon && <i className={formSetup.forms[form].headingIcon}></i>}
            {!formSetup.forms[form].titletranslation && formSetup.forms[form].title}
            {formSetup.forms[form].titletranslation && (
              <FormattedMessage
                id={formSetup.forms[form].titletranslation}
                defaultMessage={formSetup.forms[form].title}
              />
            )}
          </h3>
          <Alert color="success" isOpen={showSuccess} fade={true}>
            {responseData && !formSetup.forms[form].successmessagetranslation && formSetup.forms[form].successmessage}
            {responseData && formSetup.forms[form].successmessagetranslation && (
              <FormattedMessage
                id={formSetup.forms[form].successmessagetranslation}
                defaultMessage={formSetup.forms[form].successmessage}
              />
            )}
            {responseData && formSetup.forms[form].link.show && (
              <Link to={linkPath}>{formSetup.forms[form].link.message}</Link>
            )}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="formColumn">
          {formSubmitting && <Spinner size="md" color="orange" />}
          <Formik
            enableReinitialize={true}
            initialValues={setupValues}
            validate={(values) => {
              let errors = {};
              formSetup.forms[form].fields.map((field, key) => {
                if (field.required) {
                  if (!values[field["name"]]) {
                    errors[field["name"]] = field.errors.required;
                  }
                  if (field.conditionValue && field.conditionValue !== setupValues[field.condition]) {
                    delete errors[field["name"]];
                  }
                }
                if (field.type === "email") {
                  if (!isEmail(values[field["name"]])) {
                    errors[field["name"]] = field.errors.email;
                  }
                }
                return null;
              });
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setFormValues(values);
              if (formSetup.forms[form].submitconfirm) {
                setModalOpen(true);
              } else {
                doSubmit(values);
              }
            }}
          >
            {({ isSubmitting, validateForm }) => (
              <Form className={"submitting-" + formSubmitting}>
                {fields}
                <Col size="12">
                  <Button type="submit" disabled={formSubmitting} className={formSetup.forms[form].submitClass}>
                    {formSetup.forms[form].submitIcon && <i className={formSetup.forms[form].submitIcon}></i>}
                    {!formSetup.forms[form].submittranslation && formSetup.forms[form].submit}
                    {formSetup.forms[form].submittranslation && (
                      <FormattedMessage
                        id={formSetup.forms[form].submittranslation}
                        defaultMessage={formSetup.forms[form].submit}
                      />
                    )}
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <div>
        <Modal isOpen={modalOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <FormattedMessage id="are.you.sure" defaultMessage="Are you sure?" />
          </ModalHeader>
          <ModalBody>
            {formSetup.forms[form].submitconfirmtranslation && (
              <FormattedMessage
                id={formSetup.forms[form].submitconfirmtranslation}
                defaultMessage={formSetup.forms[form].submitconfirm}
              />
            )}
            {!formSetup.forms[form].submitconfirmtranslation && <span>{formSetup.forms[form].submitconfirm}</span>}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => doSubmit()}>
              <FormattedMessage id="ok" defaultMessage="OK" />
            </Button>
            <Button color="default" onClick={toggle}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default injectIntl(FormBuilder);
