const menuItems = [
  {
    items: [
      {
        link: "http://synergysports.com/contact/",
        text: "Support",
        translation: "support"
      },
      {
        link: "https://sportradar.com/single-sign-on-sso-privacy-notice/",
        text: "Privacy",
        translation: "privacy"
      },
      {
        link: "http://synergysports.com/terms/",
        text: "Terms",
        translation: "terms"
      }
    ]
  }
];
export default menuItems;
