import React, { useEffect, useState } from "react";

import { useFetch } from "../Hooks/fetch";

const LookupField = (props) => {
  const { lookup } = props;
  const [lookupData, setLookupData] = useState([]);
  const { error, loading, data } = useFetch("/" + lookup.endpoint, "");
  useEffect(() => {
    let array = data !== null ? data.data : [];
    setLookupData(array);
  }, [data]);

  return (
    <React.Fragment>
      <option value={null}>None</option>
      {!loading &&
        lookupData &&
        lookupData.map((option) => (
          <option value={option[lookup.value]} key={option[lookup.value]}>
            {option[lookup.text]}
          </option>
        ))}
      {error}
    </React.Fragment>
  );
};

export default LookupField;
