export const ENVIRONMENT_LOCAL = "dev";
export const ENVIRONMENT_DEFAULT = "prod";

const SENTRY_COMMON = {
  dsn: import.meta.env.SENTRY_DSN,
  release: import.meta.env.PROJECT_VERSION || "1.0.0",
  tracesSampleRate: 1.0
};

export const menuEnvMap = {
  // Legacy
  sandpit: "dev",
  nonprod: "stg",
  // Alias
  devrad: "dev",
  stgrad: "stg",
  prodrad: "prod",
  // Direct
  dev: "dev",
  stg: "stg",
  prod: "prod"
};

export const environments = {
  location: {
    // Local dev
    dev: "https://api.dc.sandpit.cloud.atriumsports.com",
    // Atrium
    sandpit: "https://api.dc.sandpit.cloud.atriumsports.com",
    nonprod: "https://api.dc.nonprod.cloud.atriumsports.com",
    prod: "https://api.dc.prod.cloud.atriumsports.com",
    // SportRadar
    devrad: "https://api.dc.dev.connect-nonprod.sportradar.dev",
    stgrad: "https://api.dc.stg.connect-nonprod.sportradar.dev",
    prodrad: "https://api.dc.connect.sportradar.com"
  },
  token: {
    // Local dev
    dev: "https://token.sandpit.cloud.atriumsports.com",
    // Atrium
    sandpit: "https://token.sandpit.cloud.atriumsports.com",
    nonprod: "https://token.nonprod.cloud.atriumsports.com",
    prod: "https://token.prod.cloud.atriumsports.com",
    // SportRadar
    devrad: "https://token.dev.connect-nonprod.sportradar.dev",
    stgrad: "https://token.stg.connect-nonprod.sportradar.dev",
    prodrad: "https://token.connect.sportradar.com"
  },
  basedomain: {
    // Local dev
    dev: "localhost",
    // Atrium
    sandpit: "sandpit.cloud.atriumsports.com",
    nonprod: "nonprod.cloud.atriumsports.com",
    prod: "prod.cloud.atriumsports.com",
    // SportRadar
    devrad: "dev.connect-nonprod.sportradar.dev",
    stgrad: "stg.connect-nonprod.sportradar.dev",
    prodrad: "connect.sportradar.com"
  },
  application: {
    dev: 21,
    // Atrium
    sandpit: 21,
    nonprod: 16,
    prod: 16,
    // SportRadar
    devrad: 21,
    stgrad: 16,
    prodrad: 16
  },
  envProductLinksMap: {
    local: {
      "admin.dc": "https://admin.dc.dev.connect-nonprod.sportradar.dev",
      evo: "https://evo.sandpit.cloud.atriumsports.com",
      ca: "https://ca.sandpit.cloud.atriumsports.com",
      pm: "https://pm.dev.connect-nonprod.sportradar.dev",
      vm: "https://vm.dev.connect-nonprod.sportradar.dev",
      um: "https://um.sandpit.cloud.atriumsports.com"
    },
    dev: {
      "admin.dc": "https://admin.dc.dev.connect-nonprod.sportradar.dev",
      evo: "https://evo.sandpit.cloud.atriumsports.com",
      ca: "https://ca.sandpit.cloud.atriumsports.com",
      pm: "https://pm.dev.connect-nonprod.sportradar.dev",
      vm: "https://vm.dev.connect-nonprod.sportradar.dev",
      um: "https://um.sandpit.cloud.atriumsports.com"
    },
    stg: {
      "admin.dc": "https://admin.dc.stg.connect-nonprod.sportradar.dev",
      evo: "https://evo.nonprod.cloud.atriumsports.com",
      ca: "https://ca.nonprod.cloud.atriumsports.com",
      pm: "https://pm.stg.connect-nonprod.sportradar.dev",
      vm: "https://vm.stg.connect-nonprod.sportradar.dev",
      um: "https://um.nonprod.cloud.atriumsports.com"
    },
    prod: {
      "admin.dc": "https://admin.dc.connect.sportradar.com",
      evo: "https://evo.prod.cloud.atriumsports.com",
      ca: "https://ca.prod.cloud.atriumsports.com",
      pm: "https://pm.connect.sportradar.com",
      vm: "https://vm.connect.sportradar.com",
      um: "https://um.prod.cloud.atriumsports.com"
    }
  }
};

export const getEnvironment = (defaultEnv) => {
  let host = window.location.hostname;
  let domainEnv = defaultEnv; // if not specified
  if (host.includes("localhost")) {
    domainEnv = ENVIRONMENT_LOCAL;
  } else {
    // host names to env stages
    for (const [env, domain] of Object.entries(environments.basedomain)) {
      // console.debug(env, "Host", host, "includes domain", domain, host.includes(domain));
      if (host.includes(domain)) {
        domainEnv = env;
        break;
      }
    }
  }
  const nodeEnv = import.meta.env.NODE_ENV;
  const searchParams = new URLSearchParams(window.location.search);
  const queryEnv = searchParams.get("env");
  const buildEnv = import.meta.env.ENVIRONMENT;
  // Env inference
  let decodedEnv = domainEnv; // infer from domain (keep backwards compatibility)
  decodedEnv = buildEnv || decodedEnv; // infer from build
  decodedEnv = queryEnv || decodedEnv; // infer from query string
  console.debug("Current environment is", decodedEnv, {
    nodeEnv,
    domainEnv,
    buildEnv,
    queryEnv
  });
  return decodedEnv;
};

export const ENVIRONMENT = getEnvironment(ENVIRONMENT_DEFAULT);

export const getSentryConfig = () => {
  return {
    ...SENTRY_COMMON,
    // Auto-inject current environment
    environment: ENVIRONMENT,
    // Disable tracing for local domains unless enforced by the developer
    denyUrls: import.meta.env.TRACING_FORCE ? [] : ["0.0.0.0", "localhost", "127.0.0.1", "::1"],
    debug: typeof import.meta.env.TRACING_DEBUG != "undefined"
  };
};

export const getCurrentSport = () => {
  let sport = "basketball";
  try {
    const decoded = JSON.parse(localStorage.getItem("currentSport"));
    sport = decoded || sport;
  } catch (error) {
    console.error("Unable to decode sport", error);
  }
  return sport;
};
