import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";

import { useFetch } from "../Base/Hooks/fetch.js";
import PageDisplay from "../Base/PageDisplay/PageDisplay.jsx";
import FormBuilder from "../Base/Forms/FormBuilder.jsx";
import formSetup from "./formSetup.js";
import timeZones from "../../config/timeZones.js";
import countryCodes from "../../config/countryCodes.js";

const EditMasterVenue = (props) => {
  const { title, history, match, sport } = props;
  const [refreshData, setRefreshData] = useState(true);
  const [currentMasterVenue, setCurrentMasterVenue] = useState(null);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + sport + "/masterVenues/" + match.params.masterVenueId + "?hideNull=false",
    "",
    refreshData
  );
  const LookupData = [];
  LookupData["timeZones"] = timeZones;
  LookupData["countryCodes"] = countryCodes;

  useEffect(() => {
    if (data) {
      setRefreshData(false);
      setCurrentMasterVenue({ sport, ...data.data[0] });
    }
    // eslint-disable-next-line
  }, [data, sport]);

  function reloadData() {
    setRefreshData(true);
  }

  return (
    <PageDisplay
      title="Update Master Venue"
      error={error}
      pageTitle={currentMasterVenue && currentMasterVenue.nameLocal + " - " + title}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner size="md" color="orange" />}
      {data && (
        <div>
          <h4>{currentMasterVenue && currentMasterVenue.nameLocal}</h4>
          {currentMasterVenue && (
            <FormBuilder
              form="EditMasterVenue"
              action={reloadData}
              formSetup={formSetup}
              formData={currentMasterVenue}
              pageRef={pageRef}
              lookupData={LookupData}
            />
          )}
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default EditMasterVenue;
