import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";

import { useFetch } from "../Base/Hooks/fetch.js";
import PageDisplay from "../Base/PageDisplay/PageDisplay.jsx";
import FormBuilder from "../Base/Forms/FormBuilder.jsx";
import formSetup from "./formSetup.js";
import locales from "../../config/locales.js";

const EditFixtureInput = (props) => {
  const { title, history, match, sport } = props;
  const [refreshData, setRefreshData] = useState(true);
  const [currentFixtureInput, setCurrentFixtureInput] = useState(null);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + sport + "/video/inputs/" + match.params.videoInputId + "?hideNull=false",
    "",
    refreshData
  );
  const LookupData = [];
  LookupData["locales"] = locales;

  useEffect(() => {
    if (data && data.data && data.data[0]) {
      setRefreshData(false);
      setCurrentFixtureInput({ sport, ...data.data[0] });
    }
    // eslint-disable-next-line
  }, [data, sport]);

  function reloadData() {
    setRefreshData(true);
  }

  return (
    <PageDisplay
      title="Update Fixture Video Input"
      error={error}
      pageTitle={"Update Fixture Video Input - " + title}
      ref={pageRef}
      history={history}
    >
      {loading && <Spinner size="md" color="orange" />}
      {currentFixtureInput && (
        <div>
          <h4>{currentFixtureInput.name}</h4>
          <FormBuilder
            form="EditFixtureInput"
            action={reloadData}
            formSetup={formSetup}
            formData={currentFixtureInput}
            pageRef={pageRef}
            lookupData={LookupData}
          />
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default EditFixtureInput;
