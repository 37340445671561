import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";

import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Auth0Provider } from "./Auth/react-auth0-wrapper";
import { GetVar } from "./Components/Base/Extras/Utilities/GetQueryVariable";
import { ENVIRONMENT } from "./config/Environment";
import { setupLanguage } from "./App.i18n";
import { Tracing } from "./App.tracing";

import App from "./App";

function renderApplication() {
  const { language, locale, messages } = setupLanguage();
  const debugMode = GetVar("debug") === "true" ? true : false;
  const onRedirectCallback = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
    );
  };
  ReactDOM.render(
    <BrowserRouter>
      <Auth0Provider
        domain={import.meta.env.AUTH0_DOMAIN}
        client_id={import.meta.env.AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <IntlProvider locale={locale} messages={messages}>
          <App className="App" environment={ENVIRONMENT} language={language} locale={locale} debug={debugMode} />
        </IntlProvider>
      </Auth0Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );
}

function entryPoint() {
  try {
    Tracing.init();
    renderApplication();
  } catch (error) {
    console.error("Application bootstrap error", error);
    Tracing.capture(error);
  }
}

entryPoint();
