import React from "react";
import { Link } from "react-router-dom";

const SeasonRow = (props) => {
  const { itemIndex, result, match } = props;

  return (
    <tr key={itemIndex}>
      <td>{result.nameLocal}</td>
      <td>{result.nameLatin}</td>
      <td>{result.year}</td>
      <td className="text-right controls-3">
        <Link
          to={"/organizations/" + match.params.organizationId + "/seasons/" + result.seasonId + "/fixtures"}
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          Fixtures <i className="fas fa-chevron-right" />
        </Link>{" "}
        <Link
          to={
            "/organizations/" +
            match.params.organizationId +
            "/competitions/" +
            match.params.competitionId +
            "/seasons/" +
            result.seasonId +
            "/venues"
          }
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          Venues <i className="fas fa-chevron-right" />
        </Link>
      </td>
    </tr>
  );
};

export default SeasonRow;
