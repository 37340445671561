import React from "react";
import { Route } from "react-router";

import Venues from "./Venues";
import VenueLink from "./VenueLink";
import VenueInputs from "./VenueInputs";
import EditVenueInput from "./EditVenueInput";

const VenueRoutes = (props) => {
  const { title, updateState, sport } = props;
  return (
    <>
      <Route
        exact
        path={"/organizations/:organizationId/venues"}
        render={(props) => <Venues {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/organizations/:organizationId/venues/:venueId/link"}
        render={(props) => <VenueLink {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/organizations/:organizationId/competitions/:competitionId/seasons/:seasonId/venues"}
        render={(props) => <Venues {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/organizations/:organizationId/competitions/:competitionId/seasons/:seasonId/venues/:venueId/inputs"}
        render={(props) => <VenueInputs {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={
          "/organizations/:organizationId/competitions/:competitionId/seasons/:seasonId/venues/:venueId/inputs/:videoInputId/edit"
        }
        render={(props) => <EditVenueInput {...props} sport={sport} updateState={updateState} title={title} />}
      />
    </>
  );
};

export default VenueRoutes;
