import React from "react";
import { Link } from "react-router-dom";

const VenueRow = (props) => {
  const { itemIndex, result, match } = props;
  return (
    <tr key={itemIndex}>
      <td>{result.nameLocal}</td>
      <td>{result.nameLatin}</td>
      <td>{result.address && result.address.city}</td>
      <td>{result.address && result.countryCode}</td>
      <td className="text-right controls-3">
        <Link
          to={"/organizations/" + match.params.organizationId + "/venues/" + result.venueId + "/link"}
          title="Link to Master Venue"
          className="btn btn-outline-success btn-sm"
        >
          <i className="fas fa-plus" /> <i className="fas fa-link" />
        </Link>{" "}
        {match.params.seasonId && (
          <Link
            to={
              "/organizations/" +
              match.params.organizationId +
              "/competitions/" +
              match.params.competitionId +
              "/seasons/" +
              match.params.seasonId +
              "/venues/" +
              result.venueId +
              "/inputs"
            }
            title="Video Inputs"
            className="btn btn-outline-secondary btn-sm"
          >
            <i className="fas fa-file-video" />
          </Link>
        )}
      </td>
    </tr>
  );
};

export default VenueRow;
