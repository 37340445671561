const menuItems = [
  {
    items: [
      {
        link: "/organizations",
        text: "Organizations",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true }
        ]
      },
      {
        link: "/admin",
        text: "Admin",
        translation: "admin",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true }
        ],
        children: [
          {
            link: "/admin/mastervenues",
            text: "Master Venues",
            translation: "mastervenues",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true }
            ]
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        link: "#",
        text: "Login",
        icon: "fas fa-sign-in-alt",
        function: "login",
        conditions: [{ variable: "isAuthenticated", evaluator: false }]
      },
      {
        link: "#",
        text: "UserProfile",
        component: "userprofile",
        conditions: [{ variable: "isAuthenticated", evaluator: true }]
      },
      {
        link: "#",
        text: "LanguageSelect",
        component: "languageselect"
      },
      {
        link: "#",
        text: "",
        icon: "fas fa-sign-out-alt",
        function: "logout",
        conditions: [{ variable: "isAuthenticated", evaluator: true }]
      }
    ]
  }
];
export default menuItems;
