import React, { useState } from "react";

import MenuBuilder from "../MenuBuilder/MenuBuilder";
import menuItems from "../../Menus/SideDrawerItems";

import "./Sidedrawer.scss";

const Sidedrawer = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={"sidedrawer open-" + isOpen}>
      <button className="sidenav-toggle" aria-expanded="false" onClick={toggle}>
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </button>
      {props.logo && <img className="sideLogo" src={props.logo} alt="Logo" />}

      <MenuBuilder menuItems={menuItems} isOpen={true} data={props} />
    </div>
  );
};

export default Sidedrawer;
