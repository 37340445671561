import React from "react";

const UserProfile = (props) => {
  return (
    <div className="userProfile">
      {props.user && props.user.picture && <img src={props.user.picture} alt={props.user.name} />}
      {props.user && <span>{props.user.name}</span>}
    </div>
  );
};

export default UserProfile;
