import React, { useEffect, useState, useRef, useCallback } from "react";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch.js";

import OrganizationRow from "./OrganizationRow.jsx";
import PageDisplay from "../Base/PageDisplay/PageDisplay.jsx";
import TableDisplay from "../Base/Shared/TableDisplay.jsx";
import TablePagination from "../Base/Shared/TablePagination.jsx";
import SearchFilter from "../Base/Shared/SearchFilter.jsx";
import FormBuilder from "../Base/Forms/FormBuilder.jsx";
import formSetup from "./formSetup.js";
import countryCodes from "../../config/countryCodes.js";
import locales from "../../config/locales.js";

const Organizations = (props) => {
  const { title, updateState, history, intl, sport } = props;
  const { formatMessage } = intl;
  const [searchText, setSearchText] = useState("");
  const [paginationString, setPaginationString] = useState("limit=50&offset=0");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch(`/v1/${sport}/organizations?${paginationString}`, "", refreshFlag);
  const columns = [
    "OrganizationId",
    formatMessage({
      id: "name",
      defaultMessage: "Name"
    }),
    formatMessage({
      id: "name.local",
      defaultMessage: "Local Name"
    }),
    "Country",
    ""
  ];
  const LookupData = [];
  LookupData["regionTypes"] = ["INTERNATIONAL", "NATIONAL", "INTERSTATE", "STATE", "LOCAL"];
  LookupData["countryCodes"] = countryCodes;
  LookupData["locales"] = locales;

  useEffect(() => {
    if (data) {
      let filteredData = data !== null ? data.data : [];
      filteredData.sort(function (a, b) {
        var aMixed = a.nameLocal;
        var bMixed = b.nameLocal;
        return aMixed < bMixed ? -1 : 1;
      });

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return (
            (result.organizationId !== null &&
              result.organizationId.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.nameLocal !== null && result.nameLocal.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.nameLatin !== null && result.nameLatin.toUpperCase().indexOf(searchText.toUpperCase()) > -1) ||
            (result.countryCode !== null && result.countryCode.toUpperCase().indexOf(searchText.toUpperCase()) > -1)
          );
        });
      }
      let tableList = filteredData.map((result, index) => (
        <OrganizationRow
          key={result.organizationId}
          itemIndex={index}
          result={result}
          updateState={updateState}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
          sport={sport}
        />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sport, searchText]);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title="Organizations"
        error={error}
        pageTitle={"Organizations - " + title}
        ref={pageRef}
        history={history}
      >
        <SearchFilter doSearch={setSearchText} initial={searchText} />
        <TableDisplay containerClass="table-responsive" columns={columns} rows={tableData} loading={loading} />
        <TablePagination data={data} setPaginationString={setPaginationString} setRefreshFlag={setRefreshFlag} />
        <hr />
        <FormBuilder
          form="NewOrganization"
          action={reloadData}
          formSetup={formSetup}
          formData={{ sport }}
          pageRef={pageRef}
          lookupData={LookupData}
        />
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Organizations);
