/**
 * Shared token parsing
 * @param {string} token
 */
function getTokenDetails(token) {
  return JSON.parse(atob(token.token.split(".")[1]));
}

export function CheckScope(token, scope) {
  let hasScope = false;
  if (token) {
    return getTokenDetails(token).scopes.includes(scope);
  }
  return hasScope;
}

/**
 * Find out if user belongs to the [staff-*] scope
 * @param {string} token
 * @returns {boolean}
 */
export function CheckStaffPermissions(token) {
  if (token) {
    const { scopes } = getTokenDetails(token);
    const pattern = new RegExp(/role:staff-\w+/);
    return scopes?.some((scope) => pattern.test(scope));
  }
}
