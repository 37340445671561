import { useState, useEffect } from "react";

export const useFilterData = (data, filters) => {
  const [filteredData, setNewData] = useState(null);

  useEffect(() => {
    (async () => {
      if (data && filters) {
        let workingData = data;
        Object.keys(filters).forEach((filter) => {
          if (filters[filter] !== "") {
            workingData = workingData.filter((item) => {
              return item[filter] === filters[filter];
            });
          }
        });
        setNewData(workingData);
      }
    })();
  }, [data, filters]);
  return { filteredData };
};
