import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useUpdateToken } from "../Base/Hooks/updateToken";

import MasterVenues from "../MasterVenues/MasterVenues";
import PageDisplay from "../Base/PageDisplay/PageDisplay";

const VenueLink = (props) => {
  const { title, history, match, sport } = props;
  const [pageTitle, setPageTitle] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + sport + "/o/" + match.params.organizationId + "/venues/" + match.params.venueId + "?include=organizations",
    "",
    refreshFlag
  );

  const { tokenUpdated } = useUpdateToken(match.params.organizationId);

  useEffect(() => {
    if (data) {
      if (data.data && data.data.length > 0) {
        setPageTitle(data.data[0].nameLocal);
      }
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (tokenUpdated) {
      window.location.reload();
    }
  }, [tokenUpdated]);

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          title={pageTitle + " - link to master venue"}
          error={error}
          pageTitle={pageTitle + " - link to master venue - " + title}
          ref={pageRef}
          history={history}
        >
          <MasterVenues {...props} sport={sport} updateState={props.updateState} title={""} />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(VenueLink);
