// src/react-auth0-wrapper.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0RuntimeProvider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const context = {
    isAuthenticated,
    user,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
    loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
    getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
    getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
    logout: (...p) => auth0Client.logout(...p)
  };

  return <Auth0Context.Provider value={context}>{children}</Auth0Context.Provider>;
};

export const Auth0TestingProvider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const auth0Client = {
    getIdTokenClaims: (...p) => console.debug("mock.getIdTokenClaims", ...p),
    loginWithRedirect: (...p) => console.debug("mock.loginWithRedirect", ...p),
    getTokenSilently: (...p) => {
      console.debug("mock.getTokenSilently", ...p);
      return Promise.resolve("v2CQ9svUd0ViKDrZkzS_wGoRtxOD8t9o");
    },
    getTokenWithPopup: (...p) => console.debug("mock.getTokenWithPopup", ...p),
    logout: (...p) => console.debug("mock.logout", ...p)
  };

  const context = {
    isAuthenticated: true,
    user: {
      email: "Valley.Callie@sportradar.dev",
      email_verified: true,
      family_name: "Valley",
      given_name: "Callie",
      locale: "en",
      name: "Callie von Valley",
      nickname: "Valley.Callie",
      picture: "https://avatars.sportradar.dev/Valley.Callie@sportradar.dev",
      sub: "google-oauth2|101948217592747289948",
      updated_at: "2022-02-09T21:17:09.895Z"
    },
    loading: false,
    popupOpen: false,
    loginWithPopup: () => console.debug("mock.loginWithPopup - do nothing"),
    handleRedirectCallback: () => console.debug("mock.handleRedirectCallback - do nothing"),
    getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
    loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
    getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
    getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
    logout: (...p) => auth0Client.logout(...p)
  };

  return <Auth0Context.Provider value={context}>{children}</Auth0Context.Provider>;
};

export const isTesting = () => {
  const flag = import.meta.env.APP_TESTING || (window.parent && window.parent.__Cypress__);
  console.debug("Checking if testing environment", flag);
  return flag;
};

export const Auth0Provider = isTesting() ? Auth0TestingProvider : Auth0RuntimeProvider;
