import React from "react";
import { Link } from "react-router-dom";

const FixtureRow = (props) => {
  const { itemIndex, result, match } = props;
  return (
    <tr key={itemIndex}>
      <td>{result.teamHome}</td>
      <td>{result.teamAway}</td>
      <td>{result.venue}</td>
      <td>
        <span>{result.startTimeLocal.replace("T", " ").replace(":00", "")}</span>
        <span className="fixture-timezone">{result.timeZone}</span>
      </td>
      <td>{result.address && result.countryCode}</td>
      <td className="text-right controls-3">
        {match.params.seasonId && (
          <Link
            to={
              "/organizations/" +
              match.params.organizationId +
              "/seasons/" +
              match.params.seasonId +
              "/fixtures/" +
              result.fixtureId +
              "/inputs"
            }
            title="Video Inputs"
            className="btn btn-outline-secondary btn-sm"
          >
            <i className="fas fa-file-video" />
          </Link>
        )}
      </td>
    </tr>
  );
};

export default FixtureRow;
