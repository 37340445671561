import React from "react";
import { Route } from "react-router";

import Organizations from "./Organizations";
import Competitions from "../Competitions/Competitions";
import Seasons from "../Seasons/Seasons";

const OrganizationRoutes = (props) => {
  const { title, updateState, sport } = props;
  return (
    <>
      <Route
        exact
        path={"/organizations"}
        render={(props) => <Organizations {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/organizations/:organizationId/competitions"}
        render={(props) => <Competitions {...props} sport={sport} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/organizations/:organizationId/competitions/:competitionId/seasons"}
        render={(props) => <Seasons {...props} sport={sport} updateState={updateState} title={title} />}
      />
    </>
  );
};

export default OrganizationRoutes;
