import React from "react";

const LookupDataField = (props) => {
  const { lookup, lookupData } = props;
  const selectData = lookupData[lookup];

  return (
    <React.Fragment>
      {selectData &&
        selectData.map((option, index) => {
          return Array.isArray(option) ? (
            <option value={option[0]} key={index}>
              {option[1]} - ({option[0]})
            </option>
          ) : (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
    </React.Fragment>
  );
};

export default LookupDataField;
